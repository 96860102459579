export const environment = {
  appTitle: 'Gårdskart',
  checkAuthIntervalInMillicecounds: 60000,
  countDownBeforeLogoutMillisecounds: 60000,
  gkUrl: 'https://gardskart.nibio.no',
  imports: [],
  // kommUrl: 'https://proxy1.nibio.no',
  kommUrl: '/proxy1',
  logoutIfInactiveInMillicecounds: 1800000,
  production: true,
  secureRoutes: ['https://gardskart.nibio.no', '/backend'],
  showBetaTeaser: false,
  showGardskartNews: true,
  signInCallbackRoutingUrl: 'signInCallback/',
  signOutCallbackRoutingUri: 'signOutCallback/',
  // e2e needs to proxy since the request comes from localhost
  wmsUrl: 'https://wms.nibio.no',
};
