import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MapCanvasService } from '@services/map-canvas.service';
import { UserService } from '@services/user.service';
import { BsModalService } from 'ngx-bootstrap/modal';

import { UserData } from '../dto/UserData';
import { SignInState } from './BackendAuthClient';

// Class used as a "narrowing" interface
@Injectable()
export class AuthProvider {
  private document = inject<Document>(DOCUMENT);
  private httpClient = inject(HttpClient);
  private mapCanvas = inject(MapCanvasService);
  private modalService = inject(BsModalService);
  private router = inject(Router);
  private userService = inject(UserService);

  readonly isAuthenticated$ = null;
  readonly nibioToken$ = null;

  isAuthenticatedValue: boolean;
  nibioTokenValue: string;
  providerName$: string;

  checkAuthAndSignOutIfNotAuthenticated(): void {
    // placeholder
  }
  getSignInUrl(): Promise<string> {
    return null;
  }
  renewToken(): void {
    // placeholder
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signIn(returnUrl: string): void {
    // placeholder
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signInCallback(code: string, state: SignInState): void {
    // placeholder
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signOut(returnUrl = '/search'): void {
    // placeholder
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  signOutCallback(state: string): void {
    // placeholder
  }
  signOutLocal(): void {
    // placeholder
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  storeUserData(ud: UserData): void {
    // placeholder
  }
}
