export class MapConfig {
  static readonly BLACK = 'rgba(0, 0, 0, 1)';
  static readonly DRIFTSSENTER = 'DRIFTSSENTER';

  static readonly DURATION_DEFAULT = 700; // Map view transition duration
  static readonly EIENDOMSGRENSE = 'EIENDOMSGRENSE';
  static readonly EXTENT_PADDING = [75, 75, 75, 75]; // Extent padding default

  static readonly GARDSBRUKSNUMMER = 'GARDSBRUKSNUMMER';
  static readonly GARDSKART_PREFIX = 'GARDSKART_';
  static readonly ORTHO = 'flybilder';
  static readonly PROPERTY_COLOR = 'rgba(255, 0, 0, 0.8)';
  static readonly TRANSPARENT = 'rgba(0, 0, 0, 0)';
  static readonly ZOOMTODEFAULT = MapConfig.EIENDOMSGRENSE;
}
