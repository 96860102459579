import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ApiConfig } from '@config/api.config';
import { fixIconLinks } from '@tools/misc/fix-icon-links';
import { BehaviorSubject, Observable } from 'rxjs';

export type MapCanvas = {
  createdTime: string;
  data: any;
  hash: string;
};

export type EditDrawing = {
  id: string;
  name: string;
};

@Injectable()
export class MapCanvasService {
  private canvasData: any = null;
  private canvasHash = null;
  private debounce: any;
  private editDrawing = new BehaviorSubject<EditDrawing>(null);
  private http = inject(HttpClient);
  private showEditToast = new BehaviorSubject<boolean>(false);

  get debounceValue(): number {
    return this.debounce;
  }
  get getCanvasData(): string {
    return this.canvasData;
  }
  get getCanvasEditDrawing$(): Observable<EditDrawing> {
    return this.editDrawing.asObservable();
  }
  get getCanvasHash(): string {
    return this.canvasHash;
  }
  get getShowToast$(): Observable<boolean> {
    return this.showEditToast.asObservable();
  }

  /**
   * Set canvas data used to populate drawings on an empty map
   */
  private setCanvas(canvas: MapCanvas) {
    if (canvas) {
      this.canvasHash = canvas.hash;
      this.canvasData = canvas.data;
    }
  }

  /**
   * Clear edit drawing data.
   */
  clearEditDrawing() {
    this.editDrawing.next(null);
    this.clearEditToast();
  }

  /**
   * Remove the edit toast.
   */
  clearEditToast() {
    this.showEditToast.next(null);
  }

  /**
   * Flush post request to backend to store the drawn features on the map for the session. Returns a promise.
   * @param {object}  canvas   open layers featurecollection in KML format
   */
  flushCanvas(canvas) {
    return new Promise<void>((resolve, reject) => {
      clearTimeout(this.debounce);

      const body = JSON.stringify(canvas);
      this.http
        .post<MapCanvas>(ApiConfig.proxiedBackendUrl + 'session/', fixIconLinks(body), {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
          },
        })
        .subscribe(
          success => {
            this.setCanvas(success);
            resolve();
          },
          () => {
            reject('Error posting canvas.');
          },
          () => (this.debounce = null),
        );
    });
  }

  /**
   * Return an observable MapCanvas with all the saved data drawings
   */
  getSavedCanvas() {
    return this.http.get<MapCanvas>(ApiConfig.proxiedBackendUrl + 'session/' + this.canvasHash);
  }

  /**
   * Post request to backend to store the drawn features on the map for the session
   * @param {object}  canvas   open layers featurecollection in KML format
   */
  postCanvas(canvas) {
    clearTimeout(this.debounce);

    this.debounce = setTimeout(() => {
      const body = JSON.stringify(canvas);
      this.http
        .post<MapCanvas>(ApiConfig.proxiedBackendUrl + 'session/', fixIconLinks(body), {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
          },
        })
        .subscribe(
          success => this.setCanvas(success),
          err => console.log('Error posting canvas', err),
          () => (this.debounce = null),
        );
    }, 5000);
  }

  /**
   * Set the edit drawing data
   * @param {string} id id of drawing
   * @param {string} name name of drawing
   */
  setEditDrawing(id, name) {
    this.editDrawing.next({
      id,
      name,
    });
    this.showEditToast.next(true);
  }

  /**
   * Empty canvas data
   */
  setEmptyCanvas() {
    this.canvasHash = null;
    this.canvasData = null;
    this.clearEditDrawing();
  }

  /**
   * Set the hash used for getting canvas drawing
   */
  setHash(hash: string) {
    this.canvasHash = hash;
  }
}
