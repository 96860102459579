export class PrintConfig {
  static readonly A3 = 'A3';
  static readonly A4 = 'A4';
  static readonly ADVANCED = 'ADVANCED';

  static readonly ADVANCED_MODE = 2;
  static readonly ADVANCED_RATIO = 3 / 4;
  static readonly DEFAULT = 0;
  static readonly DEFAULT_SCALE = 500;

  static readonly DPI = 130;

  static readonly HEADLAND = 2; // teignivå
  static readonly LANDSCAPE = 'LANDSCAPE';

  static readonly LAYOUT_LANDSCAPE = 2;

  static readonly LAYOUT_PORTRAIT = 1;
  static readonly LOWER_X = 0;

  static readonly LOWER_Y = 1;
  static readonly MAP_SCALE_CONVERSION = 8;

  static readonly MM_PER_INCHES = 46;
  static readonly PAPER_A3 = 2;

  static readonly PAPER_A4 = 1;

  static readonly PORTRAIT = 'PORTRAIT';
  static readonly PRINT_BOX_COLOR_FILL = 'rgba(253, 220, 145, 0.5)';

  static readonly PRINT_BOX_COLOR_FILL_ACTIVE = 'rgba(253, 220, 145, 0.6)';
  static readonly PRINT_BOX_COLOR_LINE = 'rgba(253, 220, 145, 1)';

  static readonly PROPERTY = 1; // eiendomsnivå
  static readonly REGULAR = 'REGULAR';

  static readonly REGULAR_MODE = 1;
  static readonly REGULAR_RATIO = 8 / 10;

  static readonly SIZE_ADVANCED_A3_LANDSCAPE = { height: 727, width: 1150 };
  static readonly SIZE_ADVANCED_A3_PORTRAIT = { height: 1075, width: 802 };
  static readonly SIZE_ADVANCED_A4_LANDSCAPE = { height: 480, width: 802 };
  static readonly SIZE_ADVANCED_A4_PORTRAIT = { height: 725, width: 555 };

  static readonly SIZE_REGULAR_A3_PORTRAIT = { height: 850, width: 802 };
  static readonly SIZE_REGULAR_A4_PORTRAIT = { height: 616, width: 555 };

  static readonly UNITS_RATIO = 39.37;
  static readonly UPPER_X = 2;
  static readonly UPPER_Y = 3;
}
